<!--
 * @Autor: syq
 * @Date: 2021-07-09 19:46:03
-->
<template>
  <div id="gift">
    <div class="market-header"></div>
    <div class="store-goods">
      <div class="store-header">
        <div class="store-logo"></div>
      </div>
      <div class="store-gift">
        <p>已满足{{ giftrang }}，任选{{ giftcount }}件赠品，赠完即止</p>
      </div>
      <van-swipe-cell v-for="item in giftlist" :key="item.id">
        <div class="goods-cart">
          <div class="left">
            <van-image width="80" height="80" :src="findImg(item)" />
          </div>
          <div class="right">
            <p class="head">{{ findName(item) }}</p>
            <div class="middle">
              <van-tag
                color="#ccc"
                v-for="h in findTag(item).filter((k) => k.propertyType === 2)"
                :key="h.value"
                >{{ h.value }}</van-tag
              >
            </div>
            <div class="bottom">
              <div class="price"></div>
              <div class="stepper">
                <van-icon
                  name="cart-o"
                  @click="() => joinMarket(true, item.skuList)"
                />
              </div>
            </div>
          </div>
        </div>
      </van-swipe-cell>
    </div>
    <van-popup v-model="showMarket" v-if="showMarket" position="bottom" :style="{ height: '50%' }"
      ><Marketshop
        @add="add"
        @close="joinMarket"
        :skuList="skuList"
        :storeCode="storeCode"
        :activityNo ="activityNo"
    /></van-popup>

    <div class="footerHeight"></div>
    <van-loading size="24px" v-show="goodsfigt" vertical>加载中...</van-loading>
  </div>
</template>

<script>
import Marketshop from "./components/marketshop.vue";
import { getExtItemList, addGoodsToMarket } from "./service";
import { Toast } from "vant";
export default {
  components: { Marketshop },
  data() {
    return {
      giftlist: [],
      defaultImg: "http://www.liby.com.cn/images/logo.png",
      showMarket: false,
      checked: false,
      goodsfigt:false,
      list: [],
      giftparam: {},
      listCount: 0,
      actualAmount: 0,
      editFlag: false,
      activityNo: "",
      PoolCode: "",
      skuList: [],
      giftcount: "",
      giftrang: "",
      storeCode: "",    };
  },
  mounted() {
      (this.giftcount = this.$route.query.count),
      (this.giftrang = this.$route.query.range);
      this.activityNo = this.$route.query.activityNo
  },
  methods: {
    joinMarket(flag, skuList) {
      this.showMarket = flag;
      this.skuList = skuList;
    },
    //加入购物车
    async add(params) {
      if (params.skuQuantity > this.giftcount) {
        return Toast(`只能选择${this.giftcount}商品`);
      } else {
        const res = await addGoodsToMarket(params);
        if (res.status === "0") {
          Toast("加入成功");
          this.showMarket = false;
        } else {
          Toast(res.msg);
        }
      }
    },

    async getExtItemList() {
      this.goodsfigt = true
      const res = await getExtItemList(
        this.$route.query.activityNo,
        this.$route.query.PoolCode
      );
      this.goodsfigt = false
      if (res.status === "0") {
        this.giftlist = res.data;
        this.storeCode = res?.data?.[0].storeCode;
      } else {
        Toast(res.msg);
      }
    },

    findImg(item) {
      return item?.mediaList
        ? item.mediaList[0].url
        : "http://uat-oss-business-middle-platform.oss-cn-shenzhen.aliyuncs.com/202108202047596941428700340054843393.png?Expires=1944823679&OSSAccessKeyId=LTAI5tMBSdRFFsKwy54k3F3J&Signature=c%2B%2FQNCjX6kK7mi1wrIZlpdJjCMg%3D";
    },
    findName(item) {
      return item?.name ?? "暂无";
    },
    findTag(item) {
      return item?.propertyValueList ?? [];
    },
    toplace() {
      let flag = false;
      this.list.forEach((d) => {
        d.cartLineResDtoList.forEach((r) => {
          if (r.isChoice) {
            flag = true;
          }
        });
      });
      if (flag) {
        this.$router.push("/market");
      } else {
        Toast(`已超出当前商品满赠限制，当前商品仅满赠${5}件`);
      }
    },
  },
  created() {
    this.getExtItemList();
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
</style>
<style lang="less">
#gift {
  width: 100%;
  height: 100%;
  padding-bottom: 45px;
  .market-header {
    width: 100%;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    p {
      margin: 0;
    }
    a {
      padding: 5px 10px;
      border-radius: 5px;
      line-height: 16px;
      background-color: #1890ff;
    }
  }
  .store-goods {
    width: 100%;
    padding: 0 16px;
    margin-bottom: 30px;
    .store-header {
      width: 100%;
      //   height: 50px;
      display: flex;
      align-items: center;
      .store-logo {
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          display: inline-block;
          width: 25px;
        }
      }
      .store-title {
        flex: 1;
        text-align: left;
      }
    }
    .store-gift {
      display: flex;
      justify-content: space-between;
      background: #f58f98;
      color: rgb(255, 255, 255);
      font-size: 12px;
      p:first-child {
        margin-left: 10px;
      }
    }
    .store-total-activity {
      display: flex;
      justify-content: space-between;
      .store-activity {
        width: 275px;
        font-size: 12px;
        line-height: 15px;
        color: rgb(255, 255, 255);
        background: #f58f98;
        p {
          padding: 0 5px;
        }
      }
      .store-collect {
        width: 50px;
        text-align: center;
        font-size: 12px;
        line-height: 30px;
        color: rgb(255, 255, 255);
        background: #f58f98;
        p {
          text-align: center;
        }
      }
    }
    .goods-cart {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 8px;
      .selected {
        width: 30px;
        display: flex;
        justify-content: center;
      }
      .left {
        width: 90px;
        padding: 0 5px;
      }
      .right {
        flex: 1;
        .head {
          margin: 0;
          width: 100%;
          height: 28px;
          line-height: 14px;
          font-size: 12px;
        }
        .middle {
          margin: 8px 0;
        }
        .bottom {
          display: flex;
          padding: 0 8px;
          justify-content: space-between;
          .price {
            color: red;
            font-family: "Montserrat", sans-serif;
          }
        }
      }
    }
  }

  .market-submit {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0px;
    .market-content {
      background: #fff;
      width: 100%;
      display: flex;
      align-items: center;
      padding-left: 32px;
      .all-select {
        flex: 1;
      }
      .price-submit {
        flex: 2;
        display: flex;
        justify-content: flex-end;
        p {
          margin: 0;
          padding: 10px 7px;
        }
        p:first-child {
          color: red;
          text-align: right;
        }
        p:last-child {
          color: #000;
          font-size: 14px;
          text-align: left;
        }
      }
    }
  }

  .footerHeight {
    height: 50px;
  }

  .delete-button {
    height: 100%;
  }
}
</style>
